<template>
  <div>
    <b-modal
      size="xl"
      id="modal_select_items"
      title="Agregar producto o servicio"
      @ok="closeModalAndSelectItems"
      ok-title="Agregar"
    >
      <div class="row">
        <b-form-group label="Producto/Servicio" class="col-md-6">
          <multiselect
            v-model="formProduct.item"
            track-by="id"
            label="canonical_name"
            placeholder="Selecciona producto"
            :options="formProductProducts"
            :searchable="true"
            :allow-empty="false"
            @search-change="searchProduct"
          >
          </multiselect>
        </b-form-group>
        <b-form-group label="Almacén destino" class="col-md-6">
          <multiselect
            v-model="formProduct.warehouse"
            track-by="id"
            label="name"
            placeholder="Selecciona tipo de documento"
            :options="warehouses"
            :searchable="true"
            :allow-empty="true"
          >
          </multiselect>
        </b-form-group>
        <b-form-group label="Cantidad" class="col-md-6">
          <b-form-input type="number" v-model="formProduct.quantity">
          </b-form-input>
        </b-form-group>
        <b-form-group label="Precio" class="col-md-6">
          <b-form-input type="number" v-model="formProduct.price">
          </b-form-input>
        </b-form-group>
      </div>
    </b-modal>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <div>
              <b-form>
                <div class="row">
                  <b-form-group label="Tipo de documento" class="col-md-3">
                    <multiselect
                      v-model="form.document_type"
                      track-by="id"
                      label="name"
                      placeholder="Selecciona tipo de documento"
                      :options="document_types"
                      :searchable="true"
                      :allow-empty="false"
                    >
                    </multiselect>
                  </b-form-group>
                  <div class="col-md-1">
                    <b-form-checkbox
                      size="xl"
                      v-model="form.no_number"
                      :value="true"
                      :unchecked-value="false"
                      class="mt-10"
                      @change="disableSerieAndNumber"
                    >
                      Sin número
                    </b-form-checkbox>
                  </div>

                  <b-form-group label="Serie" class="col-md-2">
                    <b-form-input
                      v-model="form.serie"
                      type="text"
                      maxlength="10"
                      :disabled="form.no_number"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Número" class="col-md-2">
                    <b-form-input
                      v-model="form.number"
                      type="text"
                      :disabled="form.no_number"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Fec. Emision" class="col-md-2">
                    <b-form-input
                      v-model="form.issue_date"
                      type="date"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Fec. Vencimiento" class="col-md-2">
                    <b-form-input
                      v-model="form.due_date"
                      type="date"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Proveedor" class="col-md-6">
                    <multiselect
                      v-model="form.provider"
                      track-by="id"
                      label="first_name"
                      placeholder="Seleccione proveedor"
                      :options="providers"
                      :searchable="true"
                      :allow-empty="true"
                    >
                    </multiselect>
                  </b-form-group>
                  <b-form-group label="Moneda" class="col-md-2">
                    <multiselect
                      v-model="form.currency"
                      track-by="id"
                      label="name"
                      placeholder="Selecciona moneda"
                      :options="currencies"
                      :searchable="true"
                      :allow-empty="false"
                    >
                    </multiselect>
                  </b-form-group>

                  <b-form-group label="Tipo de cambio" class="col-md-2">
                    <b-form-input
                      v-model="form.exchange_rate"
                      type="number"
                      step="0.01"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group label="Redondeo" class="col-md-2">
                    <b-form-input
                      v-model="form.rounding_decimal"
                      type="number"
                      step="0.01"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
              </b-form>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-button
                  variant="primary"
                  class="mr-2"
                  @click="$bvModal.show('modal_select_items')"
                >
                  Añadir producto
                </b-button>
              </div>
            </div>
          </b-card-text>
        </b-card>
        <b-card class="mt-5">
          <b-card-text>
            <b-table
              striped
              hover
              small
              :fields="validated_fields"
              :items="form.details"
              id="validated_products"
            >
              <template #cell(total)="data">
                {{ data.item.price * data.item.quantity }}
              </template>
              <template #cell(actions)="data">
                <b-button
                  variant="outline-danger"
                  class="mb-2"
                  @click="deleteVerifiedItem(data.item.id)"
                >
                  <b-icon icon="trash" variant="danger"></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-card-text>
        </b-card>
        <b-card class="mt-5">
          <b-card-text>
            <div class="row">
              <div class="col-md-6">
                <h3>TOTAL: {{ generalTotal }}</h3>
              </div>
              <div class="col-md-6">
                <b-button type="button" variant="primary" @click="save"
                  >Guardar</b-button
                >
              </div>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Utils from "@/core/utils/utils";

export default {
  name: "BuyCreate",
  data() {
    return {
      array_totals: [],
      currencies: [],
      formProduct: { item: null, quantity: 1, price: 0.0 },
      formProductProducts: [],
      global_total: 0.0,
      id: null,
      isLoadingFormProductProducts: false,
      validated_fields: [
        { label: "Nombre", key: "name" },
        { label: "Cantidad", key: "quantity" },
        { label: "Precio", key: "price" },
        { label: "Total", key: "total" },
        { label: "Acciones", key: "actions" },
      ],
      validated_products: [],
      document_types: [],
      action: "Crear",
      categories: [],
      brands: [],
      genders: [],
      provider_product_id: null,
      providers: [],
      presentations: [],
      form: {
        serie: null,
        number: null,
        rounding_decimal: 0.0,
        no_number: false,
        exchange_rate: 0.0,
        details: [],
      },
      products: [],
      isEdit: false,
      warehouses: [],
    };
  },
  methods: {
    loadForm() {
      if (this.$route.params.id !== undefined) {
        this.isEdit = true;
        this.id = this.$route.params.id;
      }

      let urls = [
        ApiService.get(
          "core/person",
          `?is_provider=1&page_size=100000&page_number=1`
        ),
        ApiService.get("core/warehouse", "?page=1&page_size=5000"),
        ApiService.get("constants", "?name=CURRENCIES"),
        ApiService.get("constants", "?name=DOCUMENT_TYPES"),
      ];
      if (this.isEdit) {
        urls.push(ApiService.get(`core/purchase/${this.id}`));
      }
      Promise.all(urls).then((responses) => {
        this.providers = responses[0].data.results;
        this.warehouses = responses[1].data.results;
        this.currencies = responses[2].data;
        this.document_types = responses[3].data;
        this.form = this.isEdit === true ? responses[4].data : { ...this.form };

        if (this.isEdit === true) {
          this.form = {
            ...this.form,
            document_type: this.document_types.find(
              (x) => x.id === this.form.document_type
            ),
            provider: this.providers.find((x) => x.id === this.form.provider),
            currency: this.currencies.find((x) => x.id === this.form.currency),
          };
        } else {
          // Set default values
          this.form.currency = this.currencies[0];
        }
      });
    },
    disableSerieAndNumber() {
      console.log("disabling serie and number");
      this.form.serie = null;
      this.form.number = null;
    },
    save() {
      if (this.validateSave() === false) {
        return;
      }
      let method, url;
      if (this.isEdit === true) {
        method = ApiService.patch;
        url = `core/purchase/${this.id}/`;
      } else {
        method = ApiService.post;
        url = "core/purchase/";
      }

      let form = { ...this.form };
      form.document_type = Utils.isNotNullOrEmpty(this.form.document_type)
        ? this.form.document_type.id
        : null;
      form.currency = Utils.isNotNullOrEmpty(this.form.currency)
        ? this.form.currency.id
        : null;

      form.provider = Utils.isNotNullOrEmpty(this.form.provider)
        ? this.form.provider.id
        : null;
      method(url, form)
        .then(() => {
          this.$errorToast("Compra guardada");
          this.$router.push({ name: "core_buy_list" });
        })
        .catch(() => {
          this.$errorToast("Hubo un error al guardar esta compra");
        });
    },
    searchProduct(query) {
      if (query.length <= 3) {
        return false;
      }
      this.isLoadingFormProductProducts = true;
      ApiService.get(
        "core/item",
        `?page=1&page_size=100&canonical_name=${query}`
      ).then((res) => {
        this.formProductProducts = res.data.results;
      });
    },
    deleteVerifiedItem(itemId) {
      this.form.details = this.form.details.filter(
        (x) => x.id !== parseInt(itemId)
      );
    },
    closeModalAndSelectItems(bvModalEvent) {
      // Validate if the form is valid
      if (
        !Utils.isAValidNumber(this.formProduct.quantity) ||
        !Utils.isAValidNumber(this.formProduct.price) ||
        this.formProduct.warehouse === null ||
        this.formProduct.item === null
      ) {
        alert("Debe ingresar datos válidos");
        bvModalEvent.preventDefault();
        return false;
      }
      // is valid, so add to selected Items, but check if is it already added
      const findIndex = this.form.details.findIndex(
        (x) => x.id === this.formProduct.item.id
      );
      if (findIndex > -1) {
        this.form.details[findIndex].quantity = this.formProduct.quantity;
        this.form.details[findIndex].price = this.formProduct.price;
        this.form.details[findIndex].warehouse = this.formProduct.warehouse.id;
        this.form.details[findIndex].item = this.formProduct.item.id;
      } else {
        this.form.details.push({
          id: this.formProduct.item.id,
          name: this.formProduct.item.name,
          quantity: this.formProduct.quantity,
          price: this.formProduct.price,
          warehouse: this.formProduct.warehouse.id,
          item: this.formProduct.item.id,
        });
      }
    },
    getItems() {
      ApiService.get(
        `store/item`,
        `?provider_product_id=${this.provider_product_id}`
      ).then((res) => {
        console.log(
          "Se buscaron los productos para ",
          this.provider_product_id
        );
        this.$bvModal.show("modal_select_items");
        console.log(res.data);
        this.products = res.data;
      });
    },
    validateSave() {
      if (this.form.document_type === null) {
        this.$errorToast("Debe seleccionar un tipo de documento");
        return false;
      }
      if (this.form.provider === null) {
        this.$errorToast("Debe seleccionar un proveedor");
        return false;
      }
      if (this.form.currency === null) {
        this.$errorToast("Debe seleccionar una moneda");
        return false;
      }
      if (this.form.details.length === 0) {
        this.$errorToast("Debe seleccionar al menos un producto");
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.loadForm();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Crear compra" }]);
    this.form.issue_date = new Date().toISOString().slice(0, 10);
    this.form.due_date = new Date().toISOString().slice(0, 10);
  },
  computed: {
    generalTotal: function () {
      const total = this.form.details.reduce(function (total, item) {
        return total + item.quantity * item.price;
      }, 0);
      return total + parseFloat(this.form.rounding_decimal);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
